export default {
	async mounted() {
		try {
			if (this.$recaptcha.version === 3) {
				await this.$recaptcha.init()
			}
		} catch (e) {
		}
	},
	methods: {
		async getRecaptchaToken() {
			try {
				if (this.$recaptcha.version === 2) { // for version 2
					return await this.$recaptcha.getResponse()
				} else if (this.$recaptcha.version === 3) { // for version 3
					return await this.$recaptcha.execute('login')
				} else {
					return false
				}
			}
			catch (e) {
			}
		}
	},
	beforeDestroy() {
		try {

			if (this.$recaptcha.version === 2) {
				this.$recaptcha.reset()
			} else if (this.$recaptcha.version === 3) {
				this.$recaptcha.destroy()
			}
		} catch (error) {
		}
	},
};
