
import { mapState, mapGetters } from 'vuex'
import GlobalMixins from '~/mixins/globalMixins'
import FaviconMixins from '~/mixins/favicon'
import InitSocket from '~/mixins/initSocket'
import { globalVar } from '~/utils/globalVar'

export default {
    name: 'Theme3',
    mixins: [FaviconMixins, GlobalMixins, InitSocket],
    data() {
        return {
            isMobileScreen: false,
            loginModal: false,
            registerModal: false,
            country_code: 'LA',
            globalVar,
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `https://${this.$config.DOMAIN}${this.$route.fullPath}`,
                },
            ],
            meta: [
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `https://${this.$config.DOMAIN}${this.$route.fullPath}`, // This can be overridden by page-level meta tags.
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            displayRegisterModal: (state) =>
                state.theme2.settings.displayRegisterModal,
            showHoldBalance: (state) => state.sockets.showHoldBalance,
            displayGetBonusDetail: (state) =>
                state.sockets.displayGetBonusDetail,
            displayGetBonusModalState: (state) =>
                state.sockets.displayGetBonusModal,
            withdrawalStatus: (state) => state.sockets.withdrawalStatus,
            displayWithdrawalSocketState: (state) =>
                state.sockets.displayWithdrawalSocket,
        }),
        navbarName() {
            if (this.isMobileOnly()) {
                return 'Theme3MobileNavBar'
            }
            return 'Theme3DesktopNavBar'
        },
        displayGetBonusModal: {
            get() {
                return this.displayGetBonusModalState
            },
            set(value) {
                this.$store.commit('sockets/setDisplayGetBonusModal', value)
            },
        },
        displayWithdrawalSocket: {
            get() {
                return this.displayWithdrawalSocketState
            },
            set(value) {
                this.$store.commit('sockets/setDisplayWithdrawalSocket', value)
            },
        },
    },
    watch: {
        displayRegisterModal(value) {
            this.registerModal = value
        },
        $route(to, from) {
            if (!this.$cookie.get('visit'))
                this.$store.dispatch('theme2/settings/fetchVisitWebsite')
        },
    },
    created() {
        if (process.client) {
            try {
                // eslint-disable-next-line nuxt/no-globals-in-created
                this.$cookie.set('previous_url', document.referrer)
            } catch (error) {}
        }
    },
    async mounted() {
        if (document.referrer) {
            this.$cookie.set('previous_url', document.referrer)
        }
        if (this.$cookie?.get('is_change_language')) {
            this.$store.commit('settings/changeLanguageLoading', true)
        }
        if (this.systemLocale) {
            this.$recaptcha.language = this.systemLocale
        }
        if (!window?.sessionStorage?.getItem('visit')) {
            this.$store.dispatch('theme2/settings/fetchVisitWebsite')
            window.sessionStorage?.setItem('visit', 'true')
        }
        this.$store.dispatch('cms/cms', {})
        this.$store.dispatch('theme2/settings/whiteLabel')
        this.$store.dispatch('settings/supportCountryAndLanguage')
        this.$store.dispatch('theme2/settings/getSupportCountry')
        this.$store.dispatch('theme2/settings/getExchangeRate')

        // If the website is agent website, display agent floating icons
        if (this.$cookie.get('dT')?.toLowerCase() !== 'main') {
            this.$store.dispatch(
                'theme2/agent-floating-icon/agentFloatingIcons'
            )
        }
        const cookieCountryCode = this.$cookie?.get('country_code')

        if (this.isInternational) {
            this.country_code =
                cookieCountryCode === 'GO'
                    ? this?.$cookie?.get('global_reg')
                    : cookieCountryCode
        }
        await this.setDefaultLanguages()
        await this.$store.dispatch(
            'theme2/language-string/fetchLanguageStrings',
            this.$cookie.get('locale') || this.systemLocale
        )
        if (this.$cookie.get('token')) {
            this.initSocketTheme(globalVar.themes.theme3.value)
            if (this.isInternational) {
                if (this.$cookie.get('currency')) {
                    await this.$store.dispatch(
                        'settings/operationSetting',
                        this.$cookie.get('currency')
                    )
                }
            }
            await this.$store.dispatch(
                'theme2/player-bank/getBankPlayerAccount'
            )
        }
        await this.$store.commit('settings/changeLanguageLoading', false)
        this.$cookie.remove('is_change_language')
    },
    methods: {
        onResize() {
            if (process.browser) {
                const MOBILE_SCREEN_WIDTH = 1300
                this.isMobileScreen = window.innerWidth < MOBILE_SCREEN_WIDTH
                this.$store.commit(
                    'theme2/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'theme2/settings/setIsMobile',
                this.isMobileScreen
            )
        },
        onShowLoginModal() {
            this.loginModal = true
        },
        onShowRegisterModal() {
            this.registerModal = true
        },
        closeHoldBalanceDialog() {
            this.$store.commit('sockets/setShowHoldBalance', false)
        },
    },
}
