const MobileDetect = require('mobile-detect');

export default ({ app, req, $cookie }, inject) => {
	if (process.server) {
		try {
			const ua = req.headers['user-agent'];

			const md = new MobileDetect(ua)

			const mobile = md.phone() !== null || md.mobile() === 'UnknownMobile'

			const isMobile = !!mobile;

			app.store.commit('theme2/settings/setIsMobile', isMobile);

			if ($cookie?.get('token')) {
				app.store.commit('settings/setIsLogin', true);
			}
		} catch (error) {

		}
	}
};