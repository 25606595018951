export default function (context) {
	// converting local time to UTC time
	const utcTime = (date) => {
		if (date) {
			return context.$moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
		}
		return context.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
	}

	// On Request
	context.$axios.onRequest((config) => {
		if (process.client) {
			// production
			config.headers.common['agent-domain'] = window?.location?.host
			// testing purposes
			// config.headers.common['agent-domain'] = 'wing90.com'
		}
		const params = { ...config.params } || {}

		// Check if the request have dates
		if (params.start_date) params.start_date = utcTime(params.start_date)
		if (params.end_date) params.end_date = utcTime(params.end_date)
		// set utc time to the header
		for (const [key, value] of Object.entries(params)) {
			if (value) {
				config.params[key] = value;
			} else {
				delete config.params[key]
			}
		}
		if (process.server) {
			// This is for Favicon purpose only
			// If the route is get default favicon routes
			if (config?.url?.includes('cms/lnd/component-contents/Favicon?currency')) {
				const faviconTemporaryCurrency = context?.store?.state?.cms?.faviconTemporaryCurrency
				const cookie = config.headers?.common?.cookie;
				if (cookie) {
					const currencyMatch = cookie?.match(/currency=([A-Z]{3})$/);
					const currency = currencyMatch ? currencyMatch[1] : null;
					// Check if the browser currency cookie is set, then use the browser one
					if (currency) {
						config.url = `cms/lnd/component-contents/Favicon?currency=${currency}`
					}
				} else if (faviconTemporaryCurrency) {
					config.url = `cms/lnd/component-contents/Favicon?currency=${faviconTemporaryCurrency}`
				}
			}

			config.headers.origin = context.app.$config.API_DOMAIN
		}
		config.baseURL = context.app.$config.API_DOMAIN
		if (context.$cookie.get('token')) {
			config.headers.common.Authorization = `${context.$cookie.get('token')}`
		}
	})

	// Check if response is in JSON.
	context.$axios.interceptors.response.use(response => {
		const isExist = response.headers['content-type'].indexOf('application/json');
		return isExist !== -1 ? response : Promise.reject(response);
	}, error => Promise.reject(error));

	context.$axios.onError((error) => {
		//   Sending the toast messages.
		context.$toast.clear(); // clear the old toast before showing the new toast
		if (error?.response && error?.response?.data?.message) {
			const text = context.store.getters['theme2/language-string/localeLanguage'][error.response.data.message]
			if (text) {
				context.$toast.error(text || error.response.data.message)
			} else {
				context.$toast.error(error.response.data.message)
			}
		} /* else {
			context.$toast.error('Try again after some time!')
		} */


		//   Once player Unauthorized then moved them to login page.
		if (error.response.status === 401) {
			context.store.dispatch('player/logout')
		}
	})
}
