// middleware/themeRoutes.js
import themeRoutes from '~/plugins/theme-routes';

export default function ({ $cookie, $config, route, redirect }) {
	let activeTheme = null;
	if ($config.is_development) {
		if ($cookie.get('theme')) {
			activeTheme = $cookie.get('theme')
		}
	} else if ($config.THEME) {
		activeTheme = $config.THEME
	}

	if (!activeTheme) {
		// If no theme is set, use the theme-2 as the default theme
		activeTheme = 'theme-2'
	}


	const availableRoutes = themeRoutes[activeTheme];

	const routeMatches = availableRoutes.some(pattern => {
		const regex = new RegExp('^' + pattern.replace(/:[^\s/]+/g, '([^\\s/]+)') + '$');
		return regex.test(route.path);
	});


	if (!routeMatches) {
		return redirect('/not-found'); // or any other fallback route
	}
}
