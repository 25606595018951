export default {
	data: () => ({
		search: {
			column: '',
			value: '',
		},
		options: {},
		loading: false,
		sortBy: 'created_at|desc',
		customFilters: {},
	}),
	computed: {
		footerProps() {
			return {
				itemsPerPageOptions: [10, 50, 100, 500],
				itemsPerPageText: this.tl('rowPerPage'),
			}
		},
		searchByColumn() {
			if (this.search.column !== '' && this.search.value !== '') {
				return this.search.column + '|' + this.search.value
			} else {
				return ''
			}
		},
	},
	methods: {
		async API() {
			this.loading = true

			// setting up custom parameters
			const defaultParams = {
				sort: this.sortBy,
				limit: this.options.itemsPerPage,
				page: this.options.page,
				...this.customFilters,
			}
			// Dynamic Search Object
			defaultParams.search = this.searchByColumn ? this.searchByColumn : null
			if (defaultParams.provider_id === "All") {
				delete defaultParams.provider_id;
			}
			this.loading = true
			// Export to calling main function.
			await this.$store.dispatch(this.api, defaultParams)
			this.loading = false
		},

		clearSearchFilter() {
			this.$router.go((this.$route.path))
		},
	},
	watch: {
		deep: true,
		options: {
			handler(filter) {
				if (filter.sortBy.length > 0) {
					const sortType = filter.sortDesc[0] === true ? 'desc' : 'asc'
					this.sortBy = filter.sortBy[0] + '|' + sortType
				}
				this.API()
			},
		},
	},
}
