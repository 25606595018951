// Just import this mixins into the page you need.
// It will automatically apply the favicon into that page
export default {
	async fetch() {
		// Call this API for the pages render
		/**
		 * 1). Get currency from default API
		 * 2). Save favicon icon currency by the currency from 1). by Headers.currency
		 * 3). Check if Browser has locale cookie, if yes, use cookie one, else use 1).
		 * 4). Find supported icons and set it into header
		 * 5). Set favicon in layout.vue
		 */
		await this.getFaviconIcon();
	},
	computed: {
		faviconImg() {
			return this.$store.state.cms.faviconImg
		}
	},
	methods: {
		async getFaviconIcon() {
			const defaultCurrency = await this.$store.dispatch(
				'cms/getDefaultCurrencyForFavicon',
				{}
			)
			const cookieCurrency = this.$cookie.get('currency')
			if (defaultCurrency || cookieCurrency) {
				const faviconImg = await this.$store.dispatch('cms/getFavicon', cookieCurrency || defaultCurrency)
				if (faviconImg) {
					let locale = this.$cookie.get('locale')
					const faviconTemporaryLocale = this.$store.state.cms.faviconTemporaryLocale
					if (!locale) {
						if (faviconTemporaryLocale) {
							locale = faviconTemporaryLocale
						} else {
							locale = 'en-US'
						}
					}
					this.$nuxt.$options.head.link.push({
						rel: 'icon',
						type: 'image/x-png',
						href: `${faviconImg[locale]
							? this.cmsImageLink({
								path:
									faviconImg &&
									faviconImg[locale],
								code: 'Favicon',
							})
							: ''
							}`,
					})
					this.$nuxt.$options.head.link.push({
						rel: 'apple-touch-icon',
						type: 'image/x-png',
						href: `${faviconImg[locale]
							? this.cmsImageLink({
								path:
									faviconImg &&
									faviconImg[locale],
								code: 'Favicon',
							})
							: ''
							}`,
					})
				}
			}
		},
	}
}