import { render, staticRenderFns } from "./theme-1.vue?vue&type=template&id=6d1b379b"
import script from "./theme-1.vue?vue&type=script&lang=js"
export * from "./theme-1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme1MobileNavBar: require('/var/app/components/Theme1/Mobile/NavBar.vue').default,Theme1DesktopNavBar: require('/var/app/components/Theme1/Desktop/NavBar.vue').default,MobileFooter: require('/var/app/components/Mobile/Footer.vue').default,Theme1DesktopFooter: require('/var/app/components/Theme1/Desktop/Footer.vue').default,ThemeMode: require('/var/app/components/ThemeMode.vue').default,ThemeMode: require('/var/app/components/ThemeMode.vue').default})
