
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            isMobileScreen: false,
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: `${
                        this.getLocaleLang(this.faviconIcon.image_path)
                            ? this.cmsImageLink({
                                  path: this.getLocaleLang(
                                      this.faviconIcon.image_path
                                  ),
                                  code: this.faviconIcon?.component_code,
                              })
                            : ''
                    }`,
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            faviconIcon: 'cms/faviconIcon',
            menubars: 'cms/menubars',
        }),
    },
    created() {
        if (this.$vuetify.breakpoint.mobile) {
            this.$vuetify.breakpoint.mobile = false
        }
        this.$vuetify.theme.dark = true
    },
    async mounted() {
        if (this.$cookie.get('token') && !this.menubars.length) {
            await this.$store.dispatch('language-strings/languageStrings')
            this.$store.dispatch('cms/cms', {})
        }
        if (this.$cookie.get('token')) {
            this.socket = await this.$nuxtSocket({
                reconnection: false,
                auth: {
                    Authorization: `Bearer ${this.$cookie.get('token')}`,
                },
                transports: ['websocket'],
            })
            this.socket.on('disconnect', (e) => {
                setTimeout(() => {
                    this.socket.connect()
                }, 5000)
            })
            let reconnectCount = 0
            this.socket.on('connect_error', (e) => {
                if (reconnectCount < 3) {
                    setTimeout(() => {
                        this.socket.connect()
                    }, 5000)
                    reconnectCount++
                }
            })
            this.socket.on('playerBalance', (msg) => {
                this.$store.dispatch('player/totalBalanceSocket', msg)
            })
        }
    },
    methods: {
        onResize() {
            if (process.browser) {
                this.isMobileScreen = window.innerWidth < 1300
                this.$store.commit(
                    'theme2/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'theme2/settings/setIsMobile',
                this.isMobileScreen
            )
        },
    },
}
