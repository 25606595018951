import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6f5d74ca"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThemeDefaultMobileNavBar: require('/var/app/components/ThemeDefault/Mobile/NavBar.vue').default,DesktopNav: require('/var/app/components/Desktop/Nav.vue').default,MobileFooter: require('/var/app/components/Mobile/Footer.vue').default,DesktopFooter: require('/var/app/components/Desktop/Footer.vue').default,ThemeMode: require('/var/app/components/ThemeMode.vue').default,ThemeMode: require('/var/app/components/ThemeMode.vue').default})
