import { render, staticRenderFns } from "./theme-4.vue?vue&type=template&id=c71bd436"
import script from "./theme-4.vue?vue&type=script&lang=js"
export * from "./theme-4.vue?vue&type=script&lang=js"
import style0 from "./theme-4.vue?vue&type=style&index=0&id=c71bd436&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3DownloadBanner: require('/var/app/components/Theme3/DownloadBanner.vue').default,Maintenance: require('/var/app/components/Maintenance.vue').default,ChangeLanguageLoading: require('/var/app/components/ChangeLanguageLoading.vue').default,Theme4MessageDialog: require('/var/app/components/Theme4/MessageDialog.vue').default,ThemeMode: require('/var/app/components/ThemeMode.vue').default})
